import React, { Component } from "react";
import GoogleLogin from "./auth/googleLogin";

class Login extends Component {
  state = {};
  render() {
    return (
      <div className="content">
        <h1>Login</h1>
        <p>
          We have decided to use your account from third party identity
          providers to keep your account safe.
        </p>
        <p>
          Using a technology known as Oauth2 you can use your existing account
          from any of these identity providers.
        </p>
        <p>We don't want your password and the hassle of keeping it safe.</p>
        <h2>Identity Providers</h2>
        <h3>Google</h3>
        <GoogleLogin />
      </div>
    );
  }
}

export default Login;

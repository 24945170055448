import React, { Component } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";

class GoogleCallback extends Component {
  state = {};

  async componentDidMount() {
    try {
      const { code } = queryString.parse(this.props.location.search);
      const apiUrl = process.env.REACT_APP_API_URL + "oauth/google";

      const { data: token } = await axios.post(apiUrl, {
        code: code
      });
      localStorage.setItem("token", token);
      window.location = "/";
    } catch (ex) {
      localStorage.setItem("token", null);
      this.setState({ error: ex.message });
    }
  }

  render() {
    return (
      <div className="content">
        <h1>Validating</h1>

        {!this.state.error && (
          <div className="spinners">
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-danger" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-warning" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-success" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-warning" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}

        <p>Please wait as we try to validate the response from Google.</p>

        {this.state.error && (
          <React.Fragment>
            <div className="alert alert-warning" role="alert">
              Validation failed. You have not been logged in.
            </div>
            <div className="alert alert-secondary" role="alert">
              <p>
                <strong>Error details:</strong>
                <br />
                {this.state.error}
              </p>
            </div>
            <Link to="/login" className="btn btn-primary">
              Login
            </Link>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default GoogleCallback;

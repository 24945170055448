import React, { Component } from "react";

class Terms extends Component {
  state = {};
  render() {
    return (
      <div className="content App">
        <h1>Terms of Service</h1>
      </div>
    );
  }
}

export default Terms;

import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Footer extends Component {
  state = {};
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-4">
              <span className="text-muted">
                My Name Status Copyright &copy; 2019{" "}
                <a href="https://www.antevia.se/">Antevia AB</a>.
              </span>
            </div>
            <div className="col-2">
              <NavLink to="/privacy" activeClassName="selectedLink">
                Privacy Policy
              </NavLink>
            </div>
            <div className="col-2">
              <NavLink to="/terms" activeClassName="selectedLink">
                Terms of Service
              </NavLink>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;

import React, { Component } from "react";
import settings from "../../settings.json";

const redirect_uri = "/oauth/callback/google";

class GoogleLogin extends Component {
  state = {};

  getRedirectUrl = () => {
    return `${window.location.protocol}//${
      window.location.host
    }${redirect_uri}`;
  };

  render() {
    const clientid = settings.googleClientId;

    return (
      <a
        href={`https://accounts.google.com/o/oauth2/auth?client_id=${clientid}&redirect_uri=${this.getRedirectUrl()}&response_type=code&scope=profile email&access_type=offline&state="`}
      >
        Google
      </a>
    );
  }
}

export default GoogleLogin;

import React, { Component } from "react";

class Home extends Component {
  render() {
    const { user } = this.props;

    return (
      <div className="content App">
        {user && <h2>Welcome {user.Name}</h2>}
        <h2>Coming soon</h2>
      </div>
    );
  }
}

export default Home;

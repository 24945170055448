import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import Home from "./components/home";
import Privacy from "./components/privacy";
import Terms from "./components/terms";
import NotFound from "./components/notFound";
import Login from "./components/login";
import Logout from "./components/logout";
import GoogleCallback from "./components/oauth/googleCallback";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./navbar.css";

class App extends Component {
  state = {};

  componentDidMount() {
    document.title = "My Name Status";
    this.setUser();
  }

  setUser() {
    try {
      const jwt = localStorage.getItem("token");
      let user = jwtDecode(jwt);
      console.log(user);

      const timeNow = new Date().getTime() / 1000;

      //Check if user token is expired
      if (user.exp < timeNow) {
        toast.info(
          <span>
            You have been automatically logged out.
            <br />
            Please login again.
          </span>
        );
        console.log("User token expired");
        localStorage.setItem("token", null);
        user = null;
      }
      this.setState({ user });
    } catch (ex) {
      //ignore
    }
  }

  render() {
    return (
      <Router>
        <Navbar user={this.state.user} />
        <ToastContainer />
        <Switch>
          <Route
            exact
            path="/"
            component={() => <Home user={this.state.user} />}
          />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
          <Route
            exact
            path="/oauth/callback/google"
            component={GoogleCallback}
          />

          <Route component={NotFound} />
        </Switch>
        <Footer />
      </Router>
    );
  }
}

export default App;

import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Navbar extends Component {
  state = {};
  render() {
    const { user } = this.props;
    return (
      <React.Fragment>
        <div className="text-center  bg-warning">
          <a className="text-dark" href="/">
            <h1 className="mb-0 p-3">My Name Status</h1>
          </a>
        </div>
        <nav className="navbar navbar-icon-top navbar-expand-lg navbar-dark bg-dark">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <NavLink to="/" className="nav-link">
                  <i className="fa fa-home" />
                  Home
                  <span className="sr-only">(current)</span>
                </NavLink>
              </li>
              {/* <li className="nav-item">
              <a className="nav-link" href="#">
              <i className="fa fa-hourglass-end">
              <span className="badge badge-danger">11</span>
              </i>
              Expiring
              </a>
            </li> */}
              {/* <li className="nav-item">
              <a className="nav-link disabled" href="#">
              <i className="fa fa-envelope-o">
              <span className="badge badge-warning">11</span>
              </i>
              Disabled
              </a>
            </li> */}
            </ul>
          </div>
          <ul className="navbar-nav flex-row">
            {user && (
              <li className="nav-item pr-2">
                <NavLink to="/" className="nav-link">
                  <i className="fa fa-user" />
                  {user.Name}
                </NavLink>
              </li>
            )}
            {!user && (
              <li className="nav-item">
                <NavLink to="/login" className="nav-link">
                  <i className="fa fa-sign-in" />
                  Login
                </NavLink>
              </li>
            )}
            {user && (
              <li className="nav-item">
                <NavLink to="/logout" className="nav-link">
                  <i className="fa fa-sign-out" />
                  Logout
                </NavLink>
              </li>
            )}
            {/* <li className="nav-item">
              <a className="nav-link" href="#">
              <i className="fa fa-bell">
              <span className="badge badge-info">11</span>
              </i>
              Alarms
              </a>
              </li>
              <li className="nav-item">
              <a className="nav-link" href="#">
              <i className="fa fa-globe">
              <span className="badge badge-success">11</span>
              </i>
              Test
              </a>
            </li> */}
          </ul>
        </nav>
      </React.Fragment>
    );
  }
}

export default Navbar;
